import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import enrollData from "../../state/data/enrollData";
import { Stepper, Step, StepLabel, makeStyles } from "@material-ui/core";
import moment from "moment";
import ChangePaymentOption from "./ChangePaymentOption";
import ReviewPaymentChange from "./ReviewPaymentChange";
import ChangePaymentConfirmation from "./ChangePaymentConfirmation";
import { useStateMachine } from "little-state-machine";
import updateEnrollData from "../../state/actions/updateEnrollData";
import clearEnrollData from "../../state/actions/clearEnrollData";
import { withRouter } from "react-router-dom";

const PaymentChange = ({
  history,
  disableEFTOption,
  setDisableEFTOption,
  displayBankValidationMessage,
  setDisplayBankValidationMessage,
  //counter,
  //setCounter,
}) => {
  const { state, actions } = useStateMachine({
    updateEnrollData,
    clearEnrollData,
  });

  const [backButtonDisabled, setBackButtonDisabled] = useState(false);

  const useStyles = makeStyles((theme) => ({
    iconContainer: {
      // define styles for icon container
      transform: "scale(2)",
    },
    alternativeLabel: {
      fontSize: "16px",
    },
  }));

  const classes = useStyles();

  const {
    register,
    formState: { errors, isValid, isDirty },
    trigger,
    getValues,
    control,
  } = useForm({
    mode: "all",
  });

  const [planType, setPlanType] = useState("");

  // console.log(planType);

  const [activeStep, setActiveStep] = useState(0);
  // const [formValues, setFormValues] = useState(enrollData);

  const handleNext = () => {
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    actions.updateEnrollData({
      activeStep: state.enrollData.activeStep + 1,
    });
    history.push(window.location.pathname);
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    actions.updateEnrollData({
      activeStep: state.enrollData.activeStep - 1,
    });
    history.push(window.location.pathname);
    //history.push('/mbrx-enroll-2022');
    window.scrollTo(0, 0);
  };

  const handleJumpTo = (stepIndex) => {
    //setActiveStep(stepIndex);
    actions.updateEnrollData({
      activeStep: stepIndex,
    });
    history.push(window.location.pathname);
    //history.push('/mbrx-enroll-2022');
    window.scrollTo(0, 0);
  };

  const handleChange = (input) => (e) => {
    actions.updateEnrollData({ [input]: e.target.value });
  };

  const formName = "PaymentChange";

  const setDefaultValues = useCallback(() => {

    if (state.enrollData.activeForm !== formName) {
      actions.clearEnrollData();
    }


    actions.updateEnrollData({
      recversion: "2025-01",
      transactiontype: "PAYMT",
      signature: "Y",
      datesigned: moment().format("MM/DD/YYYY"),
      //confirmationno: "6NG695", //<--remove after API is wired in
      activeForm: formName,
    });
  }, [state]);

  const [acceptTerms, setAcceptTerms] = useState(false);

  const [diffAddress, setDiffAddress] = useState(false);

  const [newToMedicare, setNewToMedicare] = useState(false);

  const [svcAreaMove, setSvcAreaMove] = useState(false);

  const [ltc, setLtc] = useState(false);

  const [return2us, setReturn2Us] = useState(false);

  const [lostCoverage, setLostCoverage] = useState(false);

  const [disenrlSnp, setDisenrlSnp] = useState(false);

  const [nonRenewal, setNonRenewal] = useState(false);

  const [lostPdpPartB, setLostPdpPartB] = useState(false);

  const [maOep, setMaOep] = useState(false);

  const [leavingEmpCoverage, setLeavingEmpCoverage] = useState(false);

  const [leavingMa, setLeavingMa] = useState(false);

  const [extraHelp, setExtraHelp] = useState(false);

  const [medicarePremCoverage, setMedicarePremCoverage] = useState(false);

  const [medicaid, setMedicaid] = useState(false);

  const [lawfulPresence, setLawfulPresence] = useState(false);

  const [chooseDifferent, setChooseDifferent] = useState(false);

  const [emergency, setEmergency] = useState(false);

  const [incarceration, setIncarceration] = useState(false);

  const [other, setOther] = useState(false);

  const [preparer, setPreparer] = useState("");

  const [callCenterAssist, setCallCenterAssist] = useState(false);

  const [birthDate, setBirthDate] = useState(null);

  function getSteps() {
    return ["Change Payment Option", "Review Changes", "Confirmation"];
  }

  const steps = getSteps();

  ///////////////////////////////////
  // Handle Back and Forward buttons
  ///////////////////////////////////
  const [locationHistory, setLocationHistory] = useState({});

  useEffect(() => {
    if (history.action === "PUSH") {
      setLocationHistory((locationHistory) => ({
        ...locationHistory,
        [history.location.key]: state.enrollData.activeStep,
      }));
    } else if (history.action === "POP") {

      if (!backButtonDisabled) {

        var stepNumber = ((history.location.key) ? locationHistory[history.location.key] : 0) ?? 0;

        if (stepNumber >= 0) {
          actions.updateEnrollData({
            activeStep: stepNumber,
          });

        }
      }

      window.scrollTo(0, 0);

    }
  }, [history.location]);

  function getStepsContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <ChangePaymentOption
            // values={formValues}
            handleChange={handleChange}
            // setFormValues={setFormValues}
            steps={steps}
            activeStep={state.enrollData.activeStep}
            handleNext={handleNext}
            planType={planType}
            setPlanType={setPlanType}
            birthDate={birthDate}
            setBirthDate={setBirthDate}
            //counter={counter}
            //setCounter={setCounter}
            // displayBankValidationMessage={displayBankValidationMessage}
            // setDisplayBankValidationMessage={setDisplayBankValidationMessage}
            // disableEFTOption={disableEFTOption}
            // setDisableEFTOption={setDisableEFTOption}
          />
        );
      case 1:
        return (
          <ReviewPaymentChange
            // values={formValues}
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleJumpTo={handleJumpTo}
            activeStep={state.enrollData.activeStep}
            acceptTerms={acceptTerms}
            steps={steps}
            diffAddress={diffAddress}
            newToMedicare={newToMedicare}
            svcAreaMove={svcAreaMove}
            ltc={ltc}
            return2us={return2us}
            lostCoverage={lostCoverage}
            disenrlSnp={disenrlSnp}
            Ks
            nonRenewal={nonRenewal}
            lostPdpPartB={lostPdpPartB}
            maOep={maOep}
            leavingEmpCoverage={leavingEmpCoverage}
            leavingMa={leavingMa}
            extraHelp={extraHelp}
            medicarePremCoverage={medicarePremCoverage}
            medicaid={medicaid}
            lawfulPresence={lawfulPresence}
            chooseDifferent={chooseDifferent}
            emergency={emergency}
            incarceration={incarceration}
            other={other}
            callCenterAssist={callCenterAssist}
            preparer={preparer}
            // setFormValues={setFormValues}
            setBackButtonDisabled={setBackButtonDisabled}
          />
        );
      case 2:
        return (
          <ChangePaymentConfirmation
            // values={formValues}
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleJumpTo={handleJumpTo}
            activeStep={state.enrollData.activeStep}
            acceptTerms={acceptTerms}
            steps={steps}
            diffAddress={diffAddress}
            newToMedicare={newToMedicare}
            svcAreaMove={svcAreaMove}
            ltc={ltc}
            return2us={return2us}
            lostCoverage={lostCoverage}
            disenrlSnp={disenrlSnp}
            Ks
            nonRenewal={nonRenewal}
            lostPdpPartB={lostPdpPartB}
            maOep={maOep}
            leavingEmpCoverage={leavingEmpCoverage}
            leavingMa={leavingMa}
            extraHelp={extraHelp}
            medicarePremCoverage={medicarePremCoverage}
            medicaid={medicaid}
            lawfulPresence={lawfulPresence}
            chooseDifferent={chooseDifferent}
            emergency={emergency}
            incarceration={incarceration}
            other={other}
            callCenterAssist={callCenterAssist}
            preparer={preparer}
            // setFormValues={setFormValues}
            setBackButtonDisabled={setBackButtonDisabled}
          />
        );

      default:
        return "Unknown Step";
    }
  }

  useEffect(() => {
    if (state.enrollData.activeForm === formName &&
      state.enrollData.activeStep === 2) {
        // Redirect to Home Page on Reload button
        history.push('/'); 
      }
    else {
    setDefaultValues();
    }
  }, []);

  // clear Enroll Data if user navigates away after clicking Send Now button
  useEffect(() => () => {
    if (backButtonDisabled) {
      actions.clearEnrollData();
      actions.updateEnrollData({ activeStep: 0, activeForm: "" });
    }
  }, []);

  return (
    <div className="dialog-off-canvas-main-canvas">
      <div
        role="main"
        className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
      >
        <div className="row d-md-flex">
          <section className="col-sm-12 internal-page">
            <div className="region region-content">
              <section
                id="block-clearstonebreadcrumbs"
                className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
              >
                <div className="breadcrumb-wrap">
                  <div className="region--breadcrumb nst-1">
                    <section
                      id="block-ttheme-breadcrumbs"
                      className="block-system block-system-breadcrumb-block"
                    >
                      <div role="navigation">
                        <ol className="breadcrumb">
                          <li>
                            <Link to="/">Home</Link>
                          </li>
                          <li>Change payment option</li>
                        </ol>
                      </div>
                    </section>
                  </div>
                </div>
              </section>
              <br />

              <Stepper
                activeStep={state.enrollData.activeStep}
                alternativeLabel
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel
                      classes={{
                        iconContainer: classes.iconContainer,
                        alternativeLabel: classes.alternativeLabel,
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>

              <div>{getStepsContent(state.enrollData.activeStep)}</div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

//////////////////////////////////////////////////////////////
// Wrap component withRouter to have access to history object
//////////////////////////////////////////////////////////////
const PaymentChangeWithRouter = withRouter(PaymentChange);

export default PaymentChangeWithRouter;
